const DEFAULT_SORT_FILTER = (key, onlySorter = false) => {
  const sorter = (a, b) => ('' + a[key]).localeCompare(b[key])
  if (onlySorter) {
    return {
      sorter
    }
  }
  return {
    sorter,
    filters: [],
    filterMode: 'menu',
    filterSearch: true,
    filterMultiple: true,
    onFilter: (value, record) => record[key].indexOf(value) === 0
  }
}

export default {
  table: {
    stockIn: {
      columns: [
        {
          title: 'Toko',
          dataIndex: 'storeCode',
          key: 'storeCode',
          ...DEFAULT_SORT_FILTER('storeCode')
        },
        {
          title: 'No. Transaksi',
          dataIndex: 'transactionNo',
          key: 'transactionNo'
        },
        {
          title: 'Tanggal',
          dataIndex: 'date',
          key: 'date',
          ...DEFAULT_SORT_FILTER('date', true)
        },
        {
          title: 'Produk',
          dataIndex: 'product',
          key: 'product',
          ...DEFAULT_SORT_FILTER('product')
        },
        {
          title: 'Kategori',
          dataIndex: 'category',
          key: 'category'
        },
        {
          title: 'Varian',
          dataIndex: 'variant',
          key: 'variant',
          ...DEFAULT_SORT_FILTER('variant')
        },
        {
          title: 'Supplier',
          dataIndex: 'supplier',
          key: 'supplier',
          ...DEFAULT_SORT_FILTER('supplier')
        },
        {
          title: 'Harga Beli',
          dataIndex: 'buyPrice',
          key: 'buyPrice'
        },
        {
          title: 'Rata-rata Harga Beli',
          dataIndex: 'avgBuyPrice',
          key: 'avgBuyPrice'
        },
        {
          title: 'Subtotal',
          dataIndex: 'subtotal',
          key: 'subtotal'
        },
        {
          title: 'Qty',
          dataIndex: 'qty',
          key: 'qty',
          ...DEFAULT_SORT_FILTER('qty', true)
        }
      ]
    },
    category: {
      columns: [
        {
          title: 'Gambar kategori',
          dataIndex: 'parentCategoryImageUrl',
          key: 'parentCategoryImageUrl'
        },
        {
          title: 'Nama kategori',
          dataIndex: 'olseraCategoryName',
          key: 'olseraCategoryName',
          ...DEFAULT_SORT_FILTER('olseraCategoryName')
        },
        {
          title: 'Prioritas',
          dataIndex: 'priority',
          key: 'priority',
          ...DEFAULT_SORT_FILTER('priority')
        },
        {
          title: 'Identifier',
          dataIndex: 'identifier',
          key: 'identifier'
        },
        {
          title: 'Tambahan biaya',
          dataIndex: 'additionalFee',
          key: 'additionalFee',
          ...DEFAULT_SORT_FILTER('additionalFee')
        },
        {
          title: 'Persentase penyesuaian harga',
          dataIndex: 'increaseProductPricePercentage',
          key: 'increaseProductPricePercentage',
          ...DEFAULT_SORT_FILTER('increaseProductPricePercentage')
        }
      ]
    }
  }
}
