import httpApi from '@/utils/http-api'
import { api } from '@/config'

export default {
  getCategories: (body = {}) => {
    return httpApi.getDataViaApi({
      url: api.categories,
      body
    })
  },
  putUpdatePriceAdjustment: (body = {}) => {
    return httpApi.putDataViaApi({
      url: api.internal.categoryPriceAdjustments,
      body
    })
  },
  putUpdatePriceAdjustments: (body = {}) => {
    return httpApi.putDataViaApi({
      url: api.internal.allCategoryPriceAdjustments,
      body
    })
  }
}
