import { defineStore } from 'pinia'
import categoryAPI from '@/api/category-api'
import { computed, ref } from 'vue'

export const useCategoryStore = defineStore('category-store', () => {
  // states (mutations)
  const setCategories = ref([])

  // getters
  const getCategories = computed(() => setCategories.value || [])
  const getParentCategories = computed(() => setCategories.value.reduce((acc, category) => {
    const existingCategory = acc.find(group => group.parentCategoryName === category.parentCategoryName)

    existingCategory
      ? existingCategory.categories.push(category)
      : acc.push({
        parentCategoryName: category.parentCategoryName,
        parentCategoryImageUrl: category.parentCategoryImageUrl,
        categories: [category]
      })

    return acc
  }, []))
  const getCategoryTreeOptions = computed(() => {
    const result = {}
    getCategories.value.forEach(category => {
      if (category && !result[category.parentCategoryName]) {
        result[category.parentCategoryName] = {
          title: category.parentCategoryName,
          key: `PARENT_${category.parentCategoryName}`,
          children: []
        }
      }

      result[category.parentCategoryName].children.push({
        title: category.olseraCategoryName,
        key: category.olseraCategoryName
      })
    })

    return Object.values(result)
  })
  const getCategoriesByParentName = computed(() => {
    return (parentName) => {
      return getCategories.value
        .filter(category => category.parentCategoryName === parentName)
        .map(category => category.olseraCategoryName)
    }
  })
  const getParentNameByCategories = computed(() => {
    return (categories) => {
      return getCategories.value
        .filter(category => categories.includes(category.olseraCategoryName))
        .map(category => `PARENT_${category.parentCategoryName}`)
    }
  })

  // actions
  const fetchCategories = ({ data } = {}) => {
    return categoryAPI.getCategories(data)
      .then(response => {
        setCategories.value = response.body.data
        return response
      })
  }
  const updateStoreCategoryPriceAdjustment = ({ data } = {}) => {
    return categoryAPI.putUpdatePriceAdjustment(data)
      .then(response => {
        return response
      })
  }
  const updateStoreCategoryPriceAdjustments = ({ data } = {}) => {
    return categoryAPI.putUpdatePriceAdjustments(data)
      .then(response => {
        return response
      })
  }

  return {
    // states
    setCategories,
    // getters
    getCategories,
    getParentCategories,
    getCategoryTreeOptions,
    getCategoriesByParentName,
    getParentNameByCategories,
    // actions
    fetchCategories,
    updateStoreCategoryPriceAdjustment,
    updateStoreCategoryPriceAdjustments
  }
})
